import { Box, Container, Slider, Typography } from '@material-ui/core';
import React, {useRef, useEffect} from 'react';
import Header from '../../components/header';

import { Helmet } from 'react-helmet';

let session = null;
let InferenceSession = null;
let Tensor = null;
const url = '/output.onnx';

//@ts-ignore
import encoder from './encoder.png';
//@ts-ignore
import decoder from './decoder.png';
//@ts-ignore
import encoder_impl from './encoder-impl.png';
//@ts-ignore
import decoder_impl from './decoder-impl.png';
import GithubIcon from '../../components/github';

async function run(x: number, y: number) {
    const t = []
    t.push(x);
    t.push(y);
    const inputs = [new Tensor(t, "float32", [1,t.length])];
    const outputMap = await session.run(inputs);
    const outputTensor = outputMap.values().next().value;
    return outputTensor;
  }
  
async function load() {
    if (session == null) return;
    await session.loadModel(url).then(() => {
    });
}

async function setupOnnx() {
    session = new InferenceSession();
    await load();
}

const headerDetails = {
    title: 'Autoencoder',
    img: '/autoencoder.png',
    description: 'Use an autoencoder to see how it can build handwritten images from an input of (x,y) value pairs.',
}

function AutoencoderPage () {

    const draw = async (ctx: CanvasRenderingContext2D) => {
        if (session == null) return;
        const canvas = ctx.canvas;
        var h = ctx.canvas.height;
        var w = ctx.canvas.width;
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        const dat = await run(x_val, y_val);
        for(var i = 0; i < 28; i++) {
          for(var j = 0; j < 28; j++) {
              var s = 4 * i * w + 4 * j;  // calculate the index in the array
              var x = dat.data[i*28 +j] * 255;  // the RGB values
    
              data[s] = x;
              data[s + 1] = x;
              data[s + 2] = x;
              data[s + 3] = 255;
          }
        }
    
        ctx.putImageData(imageData, 0, 0);
      }
    
      const canvasRef = useRef(null);
    
      const [x_val, setXValue] = React.useState(0);
      const [y_val, setYValue] = React.useState(0);
    
      const updateCanvas = () => {
        const canvas: any = canvasRef.current
        if (canvas == null) return;
        const context = canvas.getContext('2d')
        draw(context);
      }
    
      const handleXChange = (event: any, newValue: any) => {
        setXValue(newValue);
        updateCanvas();
      };
    
      const handleYChange = (event: any, newValue: any) => {
        setYValue(newValue);
        updateCanvas();
      };
      
      function tryLoad() {
        let w: any = window;
        if (w.onnx) {
            console.log('onnx loaded');
            InferenceSession = w.onnx.InferenceSession;
            Tensor = w.onnx.Tensor;
            setupOnnx().then(() => {updateCanvas();});
            return;
        }
        setTimeout(tryLoad, 500);
      }

      useEffect(() => {
        tryLoad();
      }, [])

    return (
        <Header>
            {/* @ts-ignore */}
            <Helmet>
                <script src="https://cdn.jsdelivr.net/npm/onnxjs/dist/onnx.min.js"/>
            </Helmet>
            <div style={{textAlign:'center', marginTop:'20px', marginBottom:'100px'}}>
                <Typography variant='h3'>
                    Autoencoder
                </Typography>
                <div>
                    Find the code on <GithubIcon height={'16px'} link={"https://github.com/spenc53/machine-learning/blob/main/autoencoder/autoencoder.ipynb"}/>
                </div>
                <Box>
                    <Box style={{marginTop:'30px'}}>
                        <canvas width="28" height="28" style={{height: 200, width: 200}} ref={canvasRef}/>
                    </Box>
                    <Container maxWidth="sm" style={{width: 300}}>
                        <Slider min={-5} max={10} step={.1} value={x_val} onChange={handleXChange} valueLabelDisplay="auto" aria-labelledby="continuous-slider_1" />
                        <Slider min={-10} max={5} step={.1} value={y_val} onChange={handleYChange} valueLabelDisplay="auto" aria-labelledby="continuous-slider" />
                    </Container>
                </Box>
                <Box style={{maxWidth:'800px', display:'inline-block', textAlign:'left', marginLeft:'10px', marginRight:'10px'}}>
                    <div>
                        The above is a demo displaying the output of the autoencoder that I trained. Play it with it to see how the two inputs change how the image output looks like.
                    </div>

                    {/* SECTION: HOW AUTOENCODERS WORK */}
                    <div>
                        <div style={{textAlign:'center', marginTop:'30px'}}>
                            <Typography variant='h4'>
                                How Autoencoders Work
                            </Typography>
                        </div>
                        <div>
                            An autoencoder is pretty simple in its nature. The idea is that the input and the output should be pretty similar. The general artitecture is that you have an input that gets reduced down to a smaller vector or dimension then gets built back up to it's original size. You can think of it as being made of in two parts, the encoder and the decoder. The Encoder reduces the input down to another representation and the decoder can build it back up to the orignal input.
                        </div>
                        <br></br>
                        <div style={{textAlign:'center', marginTop:'20px'}}>
                            <Typography variant='h5'>
                                The Encoder
                            </Typography>
                            <img height ='300px' style={{marginTop:'10px'}} src={encoder}/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                            The encoder works by taking an input and reducing it down. It does this by having different layers try and learn what's important and reducing that information down layer by layer. In the image, you can see that, it can take an input, run it through a few layers and then give an output. You can think of this trying to compress down important information about the input into a smaller represnetation.
                        </div>


                        <div style={{textAlign:'center', marginTop:'20px'}}>
                            <Typography variant='h5'>
                                The Decoder
                            </Typography>
                            <img height ='300px' style={{marginTop:'10px'}} src={decoder}/>
                        </div>
                        <div style={{marginTop:'10px'}}>
                            The decoder is pretty much the reverse of the encoder. It takes in some input vector and brings it back up to the orignal vector size. You can think of this taking a compressed input and trying to decompress it into the original input.
                        </div>

                        <div style={{textAlign:'center', marginTop:'30px'}}>
                            <Typography variant='h4'>
                                My Model
                            </Typography>
                        </div>
                        <div>
                            My model is pretty simple. It takes an input image of the MNIST dataset (handwritten numbers), compresses it down and then restores it. I then take the decoder part of the autoencoder explore the reduced dimensionality to see how the different numbers are seperated. You can think of this as reducing the images down to points on a 2D grid. In theory, simliar numbers should be closer together in this space (and if you check out the github link you can see this :)). Below is a simple visualization of how my network is set up.
                        </div>
                        <div style={{textAlign:'center'}}>
                            <img height ='300px' style={{marginTop:'10px'}} src={encoder_impl}/>
                            <img height ='300px' style={{marginTop:'10px'}} src={decoder_impl}/>
                        </div>

                        <div style={{marginTop: '50px', textAlign:'center'}}>
                            You can find my implementation on <a href="https://github.com/spenc53/machine-learning/blob/main/autoencoder/autoencoder.ipynb">GitHub</a>
                        </div>
                    </div>
                </Box>
            </div>
        </Header>
    )
}

export default AutoencoderPage;