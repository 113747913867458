import React from 'react';
//@ts-ignore
import githubIcon from './GitHub-Mark-32px.png'

function GithubIcon(props: any) {
    return (
        <a href={props.link} target="_blank">
            <img height={props.height ? props.height : '32px'} src={githubIcon}/>
        </a>
    )
}

export default GithubIcon;